// Generated by Framer (cec372b)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useOnVariantChange, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["JNWv88Sl8", "A9l58BUNY"];

const variantClassNames = {A9l58BUNY: "framer-v-p29tep", JNWv88Sl8: "framer-v-r0etgs"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {"Variant 1": "JNWv88Sl8", "Variant 2": "A9l58BUNY"};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

const BASE62 = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz"

function useRandomID(){const ref = React.useRef(null);
if (ref.current === null) {
ref.current = Array(5).fill(0).map(() => BASE62[Math.floor(Math.random() * BASE62.length)]).join("");
}
return ref.current;}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style: externalStyle = {}, className, width, height, layoutId, variant: outerVariant = "JNWv88Sl8", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "JNWv88Sl8", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onAppearj6bt72 = activeVariantCallback(async (...args) => {
await delay(() => setVariant("A9l58BUNY"), 4000)
})

const onAppear1kay5m5 = activeVariantCallback(async (...args) => {
await delay(() => setVariant("JNWv88Sl8"), 4000)
})

useOnVariantChange(baseVariant, {A9l58BUNY: onAppear1kay5m5, default: onAppearj6bt72})

const defaultLayoutId = useRandomID()

const { pointerEvents, ...style } = externalStyle

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div data-framer-generated initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-0mspS", classNames)} style={{display: "contents", pointerEvents: pointerEvents ?? undefined}}>
<motion.div {...restProps} className={cx("framer-r0etgs", className)} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"JNWv88Sl8"} ref={ref} style={{...style}} transition={transition} {...addPropertyOverrides({A9l58BUNY: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}><motion.div className={"framer-14hhsis"} data-border data-framer-name={"Rectangle 1251"} layoutDependency={layoutDependency} layoutId={"vRMCLCxGj"} style={{"--border-bottom-width": "1px", "--border-color": "rgb(0, 71, 187)", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", backgroundColor: "rgb(255, 255, 255)", borderBottomLeftRadius: 16.5, borderBottomRightRadius: 16.5, borderTopLeftRadius: 16.5, borderTopRightRadius: 16.5}} transition={transition}/><motion.div className={"framer-93pvnu"} data-framer-name={"Ellipse 16"} layoutDependency={layoutDependency} layoutId={"hw_hmT6Iz"} style={{backgroundColor: "rgb(0, 71, 187)", borderBottomLeftRadius: "100%", borderBottomRightRadius: "100%", borderTopLeftRadius: "100%", borderTopRightRadius: "100%"}} transition={transition}/></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-0mspS [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-0mspS * { box-sizing: border-box; }", ".framer-0mspS .framer-scx3f4 { display: block; }", ".framer-0mspS .framer-r0etgs { height: 55px; overflow: visible; position: relative; width: 33px; }", ".framer-0mspS .framer-14hhsis { flex: none; height: 55px; left: 0px; overflow: hidden; position: absolute; top: 0px; width: 33px; will-change: transform; }", ".framer-0mspS .framer-93pvnu { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 27px); left: 3px; overflow: hidden; position: absolute; top: 4px; width: 27px; will-change: transform; }", ".framer-0mspS.framer-v-p29tep .framer-93pvnu { bottom: 4px; left: calc(48.484848484848506% - 27px / 2); top: unset; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 55
 * @framerIntrinsicWidth 33
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"A9l58BUNY":{"layout":["fixed","fixed"]}}}
 */
const FramerMPKuEIORb: React.ComponentType<Props> = withCSS(Component, css) as typeof Component;
export default FramerMPKuEIORb;

FramerMPKuEIORb.displayName = "Archive / Hero toggle";

FramerMPKuEIORb.defaultProps = {height: 55, width: 33};

addPropertyControls(FramerMPKuEIORb, {variant: {options: ["JNWv88Sl8", "A9l58BUNY"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerMPKuEIORb, [])